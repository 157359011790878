import { AxiosResponse } from "axios";
import { api } from "./config";
import { ApiResponse } from "../types/apiResponseType";
import { PaymentType } from "../types/paymentType";

export const getAllPayments = async ():Promise<AxiosResponse<ApiResponse<PaymentType[]>>> => {
    try {
      const response = await api.get(`payment/all`, {});
      return response
    } catch (error: any) {
      console.log(error);
      throw(error)
    }
};