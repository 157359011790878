import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProductsPage.module.scss';
import { FaDesktop, FaChartBar, FaShoppingCart } from 'react-icons/fa';
import Footer from '../../components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { selectProduct } from '../../state/product/productSlice';
import { RootState } from '../../state/store';
import { useNavigate } from 'react-router-dom';
import { createCheckoutSessions } from '../../api/checkoutApi';

interface Product {
    name:string;
    title: string;
    price: number;
    shortDescription: string;
    features: { icon: JSX.Element; label: string }[];
    link: string;
}

const product: Product = {
    name: 'ShopForge',
    title: 'E-Commerce Site Generator',
    price: 200,
    shortDescription: 'The ultimate toolkit for modern web development.',
    features: [
        { icon: <FaDesktop />, label: 'Modern UI Components' },
        { icon: <FaChartBar />, label: 'Admin Dashboard' },
        { icon: <FaShoppingCart />, label: 'E-Commerce Generator' },
    ],
    link: 'https://ecommerce.digitlabz.fr',
};

const ProductsPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isAuthenticated, _id,  } = useSelector((state:RootState) => state.user)
    
    const handleBuyNow = async (product:{name: string, title: string, price: number}) => {
        // Dispatch product selection to Redux
        dispatch(selectProduct(product));
        // Check if user is authenticated
        if (isAuthenticated) {
            try {
                const response = await createCheckoutSessions({productName: product.name, customerId: _id as string, subscriptionPeriod: null})
                // Redirect the user to the Stripe checkout session URL
                window.location.href = response.data.data.url;
                return;
            } catch (error) {
                console.error("Error creating checkout session:", error);
                alert("An error occurred. Please try again.");
            }
        }
        else navigate('/login');
    };

    return (
        <div className={styles.productsPage}>
            <h1 className={styles.pageTitle}>{t('Our Product')}</h1>
            <div className={styles.productCard}>
                <iframe 
                    src={product.link} 
                    title={product.title} 
                    className={styles.productPreview} 
                    loading="lazy"
                />
                <h2 className={styles.productTitle}>{product.title}</h2>
                <p className={styles.productShortDescription}>{product.shortDescription}</p>
                <div className={styles.productFeatures}>
                    {product.features.map((feature, index) => (
                        <div key={index} className={styles.featureItem}>
                            <span className={styles.featureIcon}>{feature.icon}</span>
                            <span className={styles.featureLabel}>{t(feature.label)}</span>
                        </div>
                    ))}
                </div>
                <div className={styles.buttonContainer}>
                    <a href={product.link} target="_blank" rel="noopener noreferrer" className={styles.learnMoreButton}>
                        {t('Learn More')}
                    </a>
                    <button onClick={()=>handleBuyNow({name:product.name, title:product.title, price:product.price})} className={styles.buyButton}>
                        {t('Buy Now')}
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default ProductsPage;
