import { AxiosPromise, AxiosResponse } from "axios";
import { api } from "./config"
import { ApiResponse } from "../types/apiResponseType";
import { UserType } from "../types/userType";

export interface CreateCheckoutSessionData {
    productName: string;
    customerId: string;
    subscriptionPeriod?: number | null;
};

export const createCheckoutSessions = async (checkoutData:CreateCheckoutSessionData):Promise<AxiosResponse<ApiResponse<{id: string, url:string}>>> => {
    try {
        const response:AxiosResponse<ApiResponse<{id: string, url:string}>> = await api.post('create-checkout-session', checkoutData)
        return response;
    } catch(error:any) {
        console.log(error)
        throw(error)
    }
};
