// src/Pages/Admin/SubscriptionListPage.tsx

import React, { useEffect, useState } from 'react';
import styles from './SubscriptionListPage.module.scss';
import { SubscriptionType } from '../../types/subscriptionType';
import { getAllSubscriptions } from '../../api/subscriptionApi';

const SubscriptionListPage: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

  const getSubscriptions = async () => {
    try {
      const response = await getAllSubscriptions();
      if (response.status === 200) {
        setSubscriptions(response.data.data)
      }
    } catch (error: any) {
      console.log(error);
      alert('An error occurred while fetching subscriptions');
    }
  };

  // Fetch subscriptions (mocked for now, replace with actual API call if available)
  useEffect(() => {
    getSubscriptions()
  }, []);

  return (
    <div className={styles.subscriptionListPage}>
      <h1>Subscription List</h1>
      <table className={styles.subscriptionTable}>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Product ID</th>
            <th>Type</th>
            <th>Plan</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Billing History</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr key={subscription._id}>
              <td>{subscription.user.toString()}</td>
              <td>{subscription.product.toString()}</td>
              <td>{subscription.subscriptionType}</td>
              <td>{subscription.planId}</td>
              <td>{subscription.status}</td>
              <td>{new Date(subscription.startDate).toLocaleDateString()}</td>
              <td>{subscription.endDate ? new Date(subscription.endDate).toLocaleDateString() : 'N/A'}</td>
              <td>
                <ul>
                  {subscription.billingHistory.map((history, index) => (
                    <li key={index}>
                      {history.billingDate.toLocaleDateString()} - ${history.amount} ({history.paymentStatus})
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriptionListPage;
