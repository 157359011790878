// src/store/userSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo, googleAuth, login, LoginData, register, RegisterData } from '../../api/authApi';
import { saveToken } from '../../api/secureStorage';
import { UserType } from '../../types/userType';
import { ApiResponse } from '../../types/apiResponseType';

// Define the loginUser async thunk
export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (loginData: LoginData, { rejectWithValue }) => {
    try {
      const response = await login(loginData);
      saveToken(response.data.data.token)
      return response.data; // Assuming `login` returns the user data
    } catch (error: any) {
      return rejectWithValue(error.response.data.error); // Capture error message
    }
  }
);

// Define the registerUser async thunk
export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (registerData: RegisterData, { rejectWithValue }) => {
    try {
      const response = await register(registerData);
      saveToken(response.data.data.token)
      return response.data; // Assuming `register` returns the user data
    } catch (error: any) {
      return rejectWithValue(error.response.data.error); // Capture error message
    }
  }
);

// Define the fetchUserInfo async thunk
export const fetchUserInfo = createAsyncThunk(
    'user/fetchUserInfo',
    async (identifier: { userId?: string; googleId?: string; email?: string }, { rejectWithValue }) => {
      try {
        const response = await getUserInfo(identifier);
        return response.data; // Assuming the backend returns user data
      } catch (error: any) {
        return rejectWithValue(error.response.data.error);
      }
    }
  );
  

// Define the UserState interface
interface UserState {
  _id?: string;
  email: string;
  isAuthenticated: boolean;
  role: 'User' | 'Admin';
  loading: boolean;
  error: string | null;
  message: string | null;
}

// Initial state
const initialState: UserState = {
  _id:undefined,
  email: '',
  isAuthenticated: false,
  role: 'User',
  loading: false,
  error: null,
  message: null
};

// Create the user slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Partial<UserType>>) {
      state.email = action.payload.email as string; 
      state._id = action.payload._id;
      state.isAuthenticated = true;
    },
    clearUser(state) {
      state.email = '';
      state._id = undefined;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle loginUser
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<ApiResponse<{user:Partial<UserType>, token:string}>>) => {
        state.loading = false;
        state.email = action.payload.data.user.email as string;
        state._id = action.payload.data.user._id;
        state.role = action.payload.data.user.role || 'User';
        state.isAuthenticated = true;
        state.message = action.payload.message
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    // Handle registerUser
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<ApiResponse<{user:Partial<UserType>, token:string}>>) => {
        state.loading = false;
        state.email = action.payload.data.user.email as string;
        state._id = action.payload.data.user._id;
        state.role = action.payload.data.user.role || 'User';
        state.isAuthenticated = true;
        state.message = action.payload.message
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Handle fetchUserInfo
      .addCase(fetchUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<UserType>>>) => {
        state.loading = false;
        state.email = action.payload.data.email as string;
        state.role = action.payload.data.role || 'User';
        state._id = action.payload.data._id;
        state.isAuthenticated = true;
        state.message = action.payload.message
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
