// src/utils/secureStorage.ts
import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_STORAGE_SECRET_KEY || 'default_secret_key';

// Save token securely
export const saveToken = (token: string): void => {
  try {
    const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
    localStorage.setItem('authToken', encryptedToken);
  } catch (error) {
    console.error('Failed to save token:', error);
  }
};

// Retrieve token securely
export const retrieveToken = (): string | null => {
  try {
    const encryptedToken = localStorage.getItem('authToken');
    if (!encryptedToken) return null;
    const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error('Failed to retrieve token:', error);
    return null;
  }
};

// Delete token
export const deleteToken = (): void => {
  try {
    localStorage.removeItem('authToken');
  } catch (error) {
    console.error('Failed to delete token:', error);
  }
};
