// src/layouts/AdminLayout.tsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavBar from './AdminNavBar';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';

const AdminLayout: React.FC = () => {

  const user = useSelector((state:RootState) => state.user);
  return (
    <div style={{ display: 'flex' }}>
      <AdminNavBar/>
      <main style={{ flex: 1, padding: '20px' }}>
        <Outlet /> {/* This will render the nested routes */}
      </main>
    </div>
  );
};

export default AdminLayout;
