// src/Pages/Admin/OrderListPage.tsx

import React, { useEffect, useState } from 'react';
import styles from './PaymentListPage.module.scss';
import { PaymentType } from '../../types/paymentType';
import { getAllPayments } from '../../api/paymentApi';

const PaymentListPage: React.FC = () => {
  const [payments, setPayments] = useState<PaymentType[]>([]);

  const getPayments = async () => {
    try {
      const response = await getAllPayments();
      if (response.status === 200) {
        setPayments(response.data.data)
      }
    } catch (error: any) {
      console.log(error);
      alert('An error occurred while fetching payments');
    }
  };

  // Fetch orders (mocked for now, replace with actual API call if available)
  useEffect(() => {
    getPayments();
  }, []);

  return (
    <div className={styles.orderListPage}>
      <h1>Payment List</h1>
      <table className={styles.orderTable}>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Product ID</th>
            <th>Subscription ID</th>
            <th>Order Date</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Payment Status</th>
            <th>Payment Provider</th>
            <th>Transaction ID</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment._id}>
              <td>{payment.user.toString()}</td>
              <td>{payment.product ? payment.product.toString() : 'N/A'}</td>
              <td>{payment.subscription ? payment.subscription.toString() : 'N/A'}</td>
              <td>{new Date(payment.paymentDate).toLocaleDateString()}</td>
              <td>{(payment.amount / 100).toFixed(2)}</td> {/* Convert cents to dollars */}
              <td>{payment.currency}</td>
              <td>{payment.paymentStatus}</td>
              <td>{payment.paymentProvider}</td>
              <td>{payment.transactionId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentListPage;
