import { AxiosResponse } from "axios";
import { api } from "./config";
import { ApiResponse } from "../types/apiResponseType";
import { ProductType } from "../types/productType";

export const getAllProducts = async ():Promise<AxiosResponse<ApiResponse<ProductType[]>>> => {
    try {
      const response = await api.get(`product/all`, {});
      return response
    } catch (error: any) {
      console.log(error);
      throw(error)
    }
};