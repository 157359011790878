import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import NavigationTab from './components/Navigation/NavigationTab/NavigationTab1/NavigationTab';
import './App.scss';
import Footer from './components/Footer/Footer';
import { I18nextProvider } from 'react-i18next';
import i18n from './translation/i18n';
import ContactUsPage from './Pages/ContactUsPage/ContactUsPage';
import ProductsPage from './Pages/ProductsPage/ProductsPage';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import RegistrationPage from './Pages/RegistrationPage/RegistrationPage';
import LoginPage from './Pages/LoginPage/LoginPage';
import { Provider } from 'react-redux';
import store from './state/store';
import ForgotPasswordPage from './Pages/ForgotPasswordPage/ForgotPasswordPage';
import AdminLayout from './Admin/AdminLayout';
import UserListPage from './Admin/UserList/UserListPage';
import SubscriptionListPage from './Admin/SubscriptionList/SubscriptionListPage';
import PaymentListPage from './Admin/PaymentList/PaymentListPage';
import SaleListPage from './Admin/SaleList/SaleListPage';
import ProductListPage from './Admin/ProductList/ProductListPage';
import PaymentSuccessPage from './Pages/PaymentSuccessPage/PaymentSuccessPage';
import PaymentFailure from './Pages/PaymentFaillurePage/PaymentCancelPage';

const App: React.FC = () => {
  const links = [
    { text: 'Home', href: '/' },
    { text: 'Products', href: '/products' },
    { text: 'Contact Us', href: '/contact-us'}
  ];

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <AppContent links={links} />
      </I18nextProvider>
    </Router>
  );
};

const AppContent: React.FC<{ links: any[] }> = ({ links }) => {
  const location = useLocation();

  // Check if the current route includes "admin" to conditionally hide the navigation tab
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
        <section>  
          <Provider store={store}>
          <Analytics/>
          <SpeedInsights/>

          {!isAdminRoute && <NavigationTab title="DigitlabZ" links={links} />}
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/contact-us" element={<ContactUsPage/>} />
            <Route path="/products" element={<ProductsPage/>} />
            <Route path="/register" element={<RegistrationPage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
            <Route path="/payment-success" element={<PaymentSuccessPage/>} />
            <Route path="/payment-cancel" element={<PaymentFailure/>} />
            <Route path="/admin" element={<AdminLayout />}>
              <Route path="dashboard" element={<UserListPage />}/>
              <Route path="users" element={<UserListPage />}/>
              <Route path="subscriptions" element={<SubscriptionListPage />}/>
              <Route path="sales" element={<SaleListPage />}/>
              <Route path="payments" element={<PaymentListPage />}/>
              <Route path="products" element={<ProductListPage />}/>

            </Route>
          </Routes>
            
          </Provider>
        </section>
  );
};

export default App;
