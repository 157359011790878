// src/Pages/Admin/ProductListPage.tsx

import React, { useEffect, useState } from 'react';
import styles from './ProductListPage.module.scss';
import { ProductType } from '../../types/productType';
import { getAllProducts } from '../../api/productApi';

const ProductListPage: React.FC = () => {
  const [products, setProducts] = useState<ProductType[]>([]);

  const getProducts = async () => {
    try {
      const response = await getAllProducts();
      if (response.status === 200) {
        setProducts(response.data.data)
      }
    } catch (error: any) {
      console.log(error);
      alert('An error occurred while fetching products');
    }
  };
  // Fetch products (mocked for now; replace with actual API call if available)
  useEffect(() => {
    getProducts()
  }, []);

  return (
    <div className={styles.productListPage}>
      <h1>Product List</h1>
      <table className={styles.productTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Description</th>
            <th>Type</th>
            <th>Price</th>
            <th>Subscription Price</th>
            <th>Latest Version</th>
            <th>Download Link</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product._id}>
              <td>{product.name}</td>
              <td>{product.title}</td>
              <td>{product.description}</td>
              <td>{product.productType}</td>
              <td>{(product.price / 100).toFixed(2)}</td> {/* Convert cents to dollars */}
              <td>{product.isSubscription ? (product.subscriptionPrice ? (product.subscriptionPrice / 100).toFixed(2) : 'N/A') : 'N/A'}</td>
              <td>{product.latestVersion || 'N/A'}</td>
              <td>{product.downloadLink ? <a href={product.downloadLink}>Download</a> : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductListPage;
