import { AxiosPromise, AxiosResponse } from "axios";
import { api } from "./config"
import { ApiResponse } from "../types/apiResponseType";
import { UserType } from "../types/userType";

export interface LoginData {
    email: string,
    password: string
}

export const login = async (loginData:LoginData):Promise<AxiosResponse<ApiResponse<{user:Partial<UserType>, token:string}>>> => {
    try {
        const response:AxiosResponse<ApiResponse<{user:Partial<UserType>, token:string}>> = await api.post('authentication/login', loginData)
        return response;
    } catch(error:any) {
        console.log(error)
        throw(error)
    }
};

export interface RegisterData {
    email: string,
    password: string
}

export const register = async (registerData:RegisterData):Promise<AxiosResponse<ApiResponse<{user:Partial<UserType>, token:string}>>> => {
    try {
        const response:AxiosResponse<ApiResponse<{user:Partial<UserType>, token:string}>> = await api.post('authentication/register', registerData)
        return response;
    } catch(error:any) {
        console.log(error)
        throw(error)
    }
};


export const googleAuth = async () => {
    try {
        const response = await api.get('authentication/google')
        return response;
    } catch(error:any) {
        console.log(error)
        throw(error)
    }
};

export interface GetUserInfoParams {
    userId?: string;
    email?: string;
    googleToken?: string;
  }
  
export const getUserInfo = async (params: GetUserInfoParams):Promise<AxiosResponse<ApiResponse<Partial<UserType>>>>  => {
    try {
      const response = await api.get('user', { params });
      return response.data; // Return only the data for easier use in your application
    } catch (error: any) {
      console.error('Failed to retrieve user info:', error);
      throw error;
    }
};

export const resetPasswordRequest = async (email:string):Promise<AxiosResponse<ApiResponse<null>>>  => {
    try {
      const response = await api.post('authentication/request-reset-password', { email });
      return response; // Return only the data for easier use in your application
    } catch (error: any) {
      console.error('Failed to send reset password request:', error);
      throw error;
    }
};

export const resetPassword = async (token:string, password:string):Promise<AxiosResponse<ApiResponse<null>>>  => {
    try {
      const response = await api.post('authentication/reset-password', { token, password });
      return response; // Return only the data for easier use in your application
    } catch (error: any) {
      console.error('Failed to reset password:', error);
      throw error;
    }
};
