import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 5000, 
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  });
  
  // Adding authorization dynamically
  api.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

export {
    api
}
  