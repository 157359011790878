import { AxiosPromise, AxiosResponse } from "axios";
import { api } from "./config"
import { ApiResponse } from "../types/apiResponseType";
import { UserType } from "../types/userType";

export interface ContactFormData {
    name:string,
    email:string,
    subject: string,
    message:string
};

export const contactForm = async (contactFormData:ContactFormData):Promise<AxiosResponse<ApiResponse<null>>> => {
    try {
        const response:AxiosResponse<ApiResponse<null>> = await api.post('contact', contactFormData)
        return response;
    } catch(error:any) {
        console.log(error)
        throw(error)
    }
};
