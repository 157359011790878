import React, { useEffect, useState } from 'react';
import styles from './RegistrationPage.module.scss';
import { FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { loginUser, registerUser, setUser } from '../../state/user/userSlice';
import { clearSelectedProduct } from '../../state/product/productSlice';
import { saveToken } from '../../api/secureStorage';
import { register } from '../../api/authApi';
import { createCheckoutSessions } from '../../api/checkoutApi';

const RegistrationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const selectedProduct = useSelector((state: RootState) => state.product.selectedProduct);
  const { isAuthenticated, error, message, _id} = useSelector((state: RootState) => state.user);
  
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
  });
  const [passwordStrength, setPasswordStrength] = useState('');

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handlePasswordChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setFormData({ ...formData, password });
    setPasswordStrength(checkPasswordStrength(password));
  };

  const checkPasswordStrength = (password:string) => {
    if (password.length > 8 && /[A-Z]/.test(password) && /[0-9]/.test(password)) {
      return 'Strong';
    } else if (password.length >= 6) {
      return 'Medium';
    }
    return 'Weak';
  };

  const handleSubmit = (e:React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission, validate passwords, etc.
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    handleRegister()
  };

  const handleGoogleSignIn = () => {
    // Redirect to Google OAuth flow
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/authentication/google`;
  };

  const handleNavigateToLogin = () => {
    navigate('/login')
  };

  const handleCheckout = async () => {
    try {
      const response = await createCheckoutSessions({productName: selectedProduct?.name as string, customerId: _id as string, subscriptionPeriod: null})
      // Redirect the user to the Stripe checkout session URL
      window.location.href = response.data.data.url;
    } catch (error) {
        console.error("Error creating checkout session:", error);
        alert("An error occurred. Please try again.");
    } finally {
      dispatch(clearSelectedProduct()); // Clear selected product after redirecting

    }
  }

  const handleRegister = async () => {
    try {
      const response = await register(formData)
      if (response.status === 200) {
        const user = response.data.data.user
        dispatch(setUser(user))
        saveToken(response.data.data.token)

        if (user.role === "Admin") {
          return navigate('/admin');
        } else if (selectedProduct) {
          return await handleCheckout()
        } else {
          return navigate('/');
        }
      }
    } catch (error: any) {
      alert(error?.data?.message || 'An error occured during login!'); // Capture error message
    }
  }

  return (
    <div className={styles.page}>
     <div className={styles.backgroundShapes}>
      <div className={`${styles.circle} ${styles.circle1}`}></div>
      <div className={`${styles.circle} ${styles.circle2}`}></div>
     </div>
    <div className={styles.container}>
   
      <h2 className={styles.heading}>Create an Account</h2>
      <button className={styles.googleButton} onClick={handleGoogleSignIn}>
        <FaGoogle className={styles.googleIcon} /> Sign in with Google
      </button>
      <div className={styles.divider}>or</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label}>
          Full Name
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Email
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Password
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handlePasswordChange}
            required
            className={styles.input}
          />
          <div className={styles.passwordStrength}>
            Strength: <span className={styles[passwordStrength.toLowerCase()]}>{passwordStrength}</span>
          </div>
        </label>
        <label className={styles.label}>
          Confirm Password
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            name="acceptTerms"
            checked={formData.acceptTerms}
            onChange={handleChange}
            required
            className={styles.checkbox}
          />
          I accept the Terms and Conditions
        </label>
        <button type="submit" className={styles.submitButton}>
          Register
        </button>
      </form>
      <div className={styles.loginLink}>
          <div className={styles.loginButton} onClick={handleNavigateToLogin}>
            <p>Already have an account? </p>
          </div>
        </div>
    </div>
    </div>
  );
};

export default RegistrationPage;
