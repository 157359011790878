import { AxiosResponse } from "axios";
import { api } from "./config";
import { ApiResponse } from "../types/apiResponseType";
import { SubscriptionType } from "../types/subscriptionType";
import { SaleType } from "../types/saleType";

export const getAllSales = async ():Promise<AxiosResponse<ApiResponse<SaleType[]>>> => {
    try {
      const response = await api.get(`sale/all`, {});
      return response
    } catch (error: any) {
      console.log(error);
      throw(error)
    }
};