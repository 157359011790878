import React, { useState, useEffect } from 'react';
import styles from './ForgotPasswordPage.module.scss';
import { resetPasswordRequest } from '../../api/authApi';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [counter, setCounter] = useState(60);

  // Handles the email change
  const handleEmailChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // Simulates sending the reset email
  const handleSendEmail = async () => {
    setEmailSent(true);
    try {
      const response = await resetPasswordRequest(email)
      if (response.status === 200 && response.data.message) {
        alert(response.data.message)
      }
    } catch (error:any) {
      alert(error?.data?.message || error.message)
    } finally {
      setCounter(60);
    }
  };

  // Countdown logic
  useEffect(() => {
    if (emailSent && counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [emailSent, counter]);

  const handleResend = async () => {
    if (counter === 0) {
      setCounter(60);
      await handleSendEmail()
    }
  };

  return (
    <div className={styles.pageResetPasswordBackground}>
    <div className={styles.forgot_password_container}>
      {!emailSent ? (
        <div className={styles.request_container}>
          <h2>Forgot Password</h2>
          <p>Enter your email address to receive a password reset link.</p>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button onClick={handleSendEmail} disabled={!email}>
            Send Reset Link
          </button>
        </div>
      ) : (
        <div className={styles.confirmation_container}>
          <i className={styles.check_icon}>✔️</i>
          <h2>Check Your Email</h2>
          <p>We have sent a password reset link to your email.</p>
          <p className={styles.resend_message}>
            {counter > 0 ? (
              `You can resend the email in ${counter} seconds.`
            ) : (
              <span onClick={handleResend} className={styles.resend_link}>
                Resend email
              </span>
            )}
          </p>
        </div>
      )}
    </div>
    </div>
  );
};

export default ForgotPasswordPage;
