// src/components/AdminNavBar.tsx

import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaUser, FaBox, FaDollarSign, FaListAlt, FaCog, FaHome } from 'react-icons/fa';
import styles from './AdminNavBar.module.scss';

const AdminNavBar: React.FC = () => {
  return (
    <nav className={styles.drawer}>
      <div className={styles.drawerHeader}>Admin Panel</div>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <NavLink to="/admin/dashboard" className={styles.active}>
            <FaHome className={styles.icon} /> Dashboard
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/admin/users" className={styles.active}>
            <FaUser className={styles.icon} /> User List
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/admin/subscriptions" className={styles.active}>
            <FaDollarSign className={styles.icon} /> Subscription List
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/admin/sales" className={styles.active}>
            <FaDollarSign className={styles.icon} /> Sales List
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/admin/payments" className={styles.active}>
            <FaListAlt className={styles.icon} /> Payment List
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/admin/products" className={styles.active}>
            <FaBox className={styles.icon} /> Product List
          </NavLink>
        </li>
        
      </ul>
    </nav>
  );
};

export default AdminNavBar;
