import React, { useEffect, useState } from 'react';
import styles from './LoginPage.module.scss';
import { FaGoogle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you use React Router
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../state/store';
import { loginUser, setUser } from '../../state/user/userSlice';
import { googleAuth, login } from '../../api/authApi';
import { clearSelectedProduct } from '../../state/product/productSlice';
import { createCheckoutSessions } from '../../api/checkoutApi';
import { saveToken } from '../../api/secureStorage';

const LoginPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();

  const selectedProduct = useSelector((state: RootState) => state.product.selectedProduct);
  const { isAuthenticated, role, error, message, _id } = useSelector((state: RootState) => state.user);
  
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e:React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission logic here
    handleLogin()
  };

  const handleGoogleSignIn = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/authentication/google`;
  };

  const handleNavigateToRegister = () => {
    navigate('/register')
  };

  const handleCheckout = async () => {
    try {
      const response = await createCheckoutSessions({productName: selectedProduct?.name as string, customerId: _id as string, subscriptionPeriod: null})
      // Redirect the user to the Stripe checkout session URL
      window.location.href = response.data.data.url;
    } catch (error) {
        console.error("Error creating checkout session:", error);
        alert("An error occurred. Please try again.");
    } finally {
      dispatch(clearSelectedProduct()); // Clear selected product after redirecting

    }
  }

  const handleLogin = async () => {
    try {
      const response = await login(formData)
      if (response.status === 200) {
        const user = response.data.data.user
        dispatch(setUser(user))
        saveToken(response.data.data.token)

        if (user.role === "Admin") {
          return navigate('/admin');
        } else if (selectedProduct) {
          return await handleCheckout()
        } else {
          return navigate('/');
        }
      }
    } catch (error: any) {
      alert(error?.data?.message || 'An error occured during login!'); // Capture error message
    }
  }

  return (
    <div className={styles.pageBackground}>
      <div className={styles.waveBackground}></div>
      <div className={styles.container}>
        <h2 className={styles.heading}>Login to Your Account</h2>
        <button className={styles.googleButton} onClick={handleGoogleSignIn}>
          <FaGoogle className={styles.googleIcon} /> Sign in with Google
        </button>
        <div className={styles.divider}>or</div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <label className={styles.label}>
            Email
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>
          <label className={styles.label}>
            Password
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className={styles.input}
            />
          </label>
          <button type="submit" className={styles.submitButton}>
            Login
          </button>
        </form>
        <div className={styles.registerLink}>
          <span>Don’t have an account? </span>
          <div className={styles.registerButton} onClick={handleNavigateToRegister}>Register here</div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
