// src/Pages/Admin/SaleListPage.tsx

import React, { useEffect, useState } from 'react';
import styles from './SaleListPage.module.scss';
import { SaleType } from '../../types/saleType';
import { getAllSales } from '../../api/saleApi';

const SaleListPage: React.FC = () => {
  const [sales, setSales] = useState<SaleType[]>([]);

  const getSales = async () => {
    try {
      const response = await getAllSales();
      if (response.status === 200) {
        setSales(response.data.data)
      }
    } catch (error: any) {
      console.log(error);
      alert('An error occurred while fetching sales');
    }
  };

  // Fetch sales (mocked for now; replace with actual API call if available)
  useEffect(() => {
    getSales();
  }, []);

  return (
    <div className={styles.saleListPage}>
      <h1>Sale List</h1>
      <table className={styles.saleTable}>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Product ID</th>
            <th>Purchase Date</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Payment Status</th>
            <th>Payment ID</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((sale) => (
            <tr key={sale._id}>
              <td>{sale.user.toString()}</td>
              <td>{sale.product.toString()}</td>
              <td>{sale.purchaseDate ? new Date(sale.purchaseDate).toLocaleDateString() : 'N/A'}</td>
              <td>{(sale.amount / 100).toFixed(2)}</td> {/* Convert cents to dollars */}
              <td>{sale.currency}</td>
              <td>{sale.paymentStatus}</td>
              <td>{sale.payment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SaleListPage;
