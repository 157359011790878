import React, { useEffect, useState } from 'react';
import styles from './PaymentCancelPage.module.scss';

const PaymentCancelPage: React.FC = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      window.location.href = '/';
    }
  }, [countdown]);

  return (
    <div className={styles.container}>
      <div className={styles.icon}>❌</div>
      <h1 className={styles.title}>Payment Failed</h1>
      <p className={styles.message}>
        We're sorry, but your payment could not be processed. Please try again later or contact support if the issue persists.
      </p>
      <p className={styles.timer}>
        Redirecting to home page in {countdown} seconds...
      </p>
    </div>
  );
};

export default PaymentCancelPage;
