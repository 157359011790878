// src/Pages/Admin/UserListPage.tsx

import React, { useEffect, useState } from 'react';
import styles from './UserListPage.module.scss';
import { UserType } from '../../types/userType';
import { getAllUsers } from '../../api/userApi';

const UserListPage: React.FC = () => {
  const [users, setUsers] = useState<UserType[]>([]);

  const getUsers = async () => {
    try {
      const response = await getAllUsers();
      if (response.status === 200) {
        setUsers(response.data.data)
      }
    } catch (error: any) {
      console.log(error);
      alert('An error occurred while fetching users');
    }
  };

  // Mock data or fetch from API
  useEffect(() => {
    getUsers()
  }, []);

  return (
    <div className={styles.userListPage}>
      <h1>User List</h1>
      <table className={styles.userTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user._id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{new Date(user.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserListPage;
