import React from 'react';
import styles from './PaymentSuccessPage.module.scss';

const PaymentSuccessPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>✔️</div>
      <h1 className={styles.title}>Payment Successful!</h1>
      <p className={styles.message}>
        Thank you for your purchase. Please check your email for the download link.
      </p>
      <button
        className={styles.button}
        onClick={() => (window.location.href = '/')}
      >
        Back to Home
      </button>
    </div>
  );
};

export default PaymentSuccessPage;
